import React from 'react'
import PropTypes from 'prop-types'

import { Arrow } from '../../images/arrow'
import { Chip } from './style'

const Shortcuts = ({ list }) => {
  return (
    <div
      className={
        'mx-auto xl-container flex flex-col gap-4 mb-8 md:mb-11 lg:mb-16 md:flex-row '
      }
    >
      {list.map((item, index) => (
        <Chip key={index} href={item.url} target={item.target}>
          {item.title}
          <Arrow color={'orange'} />
        </Chip>
      ))}
    </div>
  )
}

export default Shortcuts

Shortcuts.propTypes = {
  list: PropTypes.arrayOf(PropTypes.object),
}
