import tw, { styled } from 'twin.macro'

export const Chip = styled.a`
  ${tw`
  text-lg 
  text-orange-dark 
  font-medium
  
  bg-orange-lighter 
  
  py-3 
  px-5 
  rounded-full 
  
  flex 
  items-center 
  gap-x-[18px] 
  justify-between
  
  hover:bg-orange-lightHover
  hover:text-blue-dark
  
  active:bg-[#FFD8BC]
  
  focus:ring 
  focus:ring-orange-light 
  `}
`
