import React from 'react'
import Layout from '../components/layout'
import { graphql } from 'gatsby'
import Aside from '../components/aside'
import Banner from '../components/banner'
import Chips from '../components/shortcuts'
import SEO from '../components/seo'

const CompetenceTemplate = ({ data, location }) => {
  const { wpPage: page } = data
  const base = page.template.templateCompetence
  const banner = page.template.componentBanner
  const asides = base.asides ?? []
  const chips = base.chipsList ?? []

  return (
    <Layout location={location}>
      <SEO
        title={page.title}
        metaTitle={page.seo.title}
        description={page.seo.metaDesc}
      />
      <Banner
        image={banner.bannerImage}
        excerpt={banner.excerpt}
        title={page.title}
        breadcrumbs={page.seo.breadcrumbs}
      />
      <Chips
        list={chips.map((item) => ({
          url: item.chipLink.url,
          title: item.chipLink.title,
          target: item.chipLink.target,
        }))}
      />

      {asides.length > 0 && (
        <div id={'asides'}>
          {asides.map((item, index) => (
            <Aside
              key={index}
              index={index}
              buttons={item.buttonList}
              title={item.title}
              content={item.content}
              media={item.media}
              isOutlined={true}
              id={item.id}
            />
          ))}
        </div>
      )}
    </Layout>
  )
}

export default CompetenceTemplate

export const query = graphql`
  query ($id: String!) {
    wpPage(id: { eq: $id }) {
      title
      template {
        ... on WpCompetenceTemplate {
          componentBanner {
            excerpt
            bannerImage {
              title
              publicUrl
              sourceUrl
            }
          }
          templateCompetence {
            chipsList {
              chipLink {
                target
                title
                url
              }
            }
            asides {
              id
              title
              content
              buttonList {
                variant
                link {
                  target
                  title
                  url
                }
              }
              media {
                mediaType
                image {
                  publicUrl
                  sourceUrl
                  title
                }
                video {
                  sourceUrl
                  publicUrl
                  title
                }
              }
            }
          }
        }
      }
      seo {
        breadcrumbs {
          text
          url
        }
        metaDesc
        title
      }
    }
  }
`
